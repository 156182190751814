import React from "react";
import styled from "styled-components";
import { graphql, Link, useStaticQuery } from "gatsby";
import { AiTwotoneMail, AiTwotonePhone } from "react-icons/ai";
import { SiTelegram } from "react-icons/si";
import { ImLocation } from "react-icons/im";
import { BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";
import Saba from "~/assets/svg/saba";
import Slider from "react-slick";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

const FooterList = styled.div`
    background: #2d2d2d;
    padding: 1rem 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    height: 100%;
    width: 100%;
    @media(max-width: 900px) {
        justify-content: center;
        padding: 0 1rem;
        flex-flow: column nowrap;
    }
`;
const Address = styled.p`
     font-family: 'Mukta', sans-serif;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: #b4b4b4;
    text-decoration: none;
`;
const FooterItem = styled.div`
    list-style: none;
    margin: 2rem 0 1rem 0;
    color: white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    gap: 2rem;
    
`;

const FooterItemRow = styled(FooterItem)`
flex-flow: row wrap;
width: 20rem;
gap: .5rem;
`;

const LinkItem = styled(Link)`
    color: #b8b5b5;
    :hover {
        color: #039d69;
    }
    text-decoration: none;
     font-family: 'Mukta', sans-serif;
    font-weight: 400;
    font-size: 1.2em;
`;

const IconContainer = styled.a`
    display: flex;
    gap: 1rem;
    color: white;
     font-family: 'Mukta', sans-serif;
    text-decoration: none;
`;
const IconHover = styled(IconContainer)`
:hover {
        color: #039d69;
    }
align-items: center;
`

const Text = styled.a`
    text-decoration: none;
    color: white;
    font-size: 1rem;
     font-family: 'Mukta', sans-serif;
    @media(max-width: 900px) {
        display: none;
    }
    :hover {
        color: #03799d;
    }
`;
const TextCursive = styled(Text)`
    font-size: 1.8rem;
    font-family: 'Roboto';
`;
const Card = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    align-content: center;
    flex-flow: row wrap;
    transition: all 2s;
    margin: 0 15%;
    background-color: #e0f9fc;
    @media(max-width: 599px) {
        margin: 0;
    }
`;

const Img = styled(GatsbyImage)`
    width: 5rem;
    height: 5rem;
    border-radius: 5px;
`;
const FooterItem1 = styled(FooterItem)`
    flex-flow: row nowrap;
`;

const query = graphql`
{   
  instagram:
  allInstagramContent(limit: 9) {
    nodes {
      localImage {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
}`;

export const Footer: React.FC = () => {
    const { instagram } = useStaticQuery(query);
    return (
        <>
            <footer>
                <FooterList>
                <FooterItem>
                <IconHover href="mailto: sales@wanderlusttourism.in" target="_blank"><Saba /></IconHover>
                <Address>60-EH, Scheme No 54, Vijay Nagar,<br/> Indore, Madhya Pradesh 452010<br/> India</Address>
                
                <FooterItem1>
                    <IconHover href="mailto: sales@wanderlusttourism.in" target="_blank"><AiTwotoneMail /><Text href="mailto: sales@wanderlusttourism.in" target="_blank">Email</Text></IconHover>
                    <IconHover href="https://wa.me/+919691111998" target="_blank"><AiTwotonePhone /><Text href="https://wa.me/+919691111998" target="_blank">Whatsapp</Text></IconHover>
                    <IconHover href="https://g.page/WanderlustTourism?share" target="_blank"><ImLocation /><Text href="https://g.page/WanderlustTourism?share" target="_blank">Location</Text></IconHover>
                </FooterItem1></FooterItem>
                <FooterItem>
                    <IconContainer style={{color: "#306fed"}} href ="https://www.facebook.com/wanderlust.tourismsindia/" target="_blank"><BsFacebook/>Facebook</IconContainer>
                    <IconContainer href ="https://instagram.com/wanderlusttourism.in?igshid=YmMyMTA2M2Y=" target="_blank"><BsInstagram style={{background: "radial-gradient(circle at 50% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)"}}/><p style={{color: "#97b7fd"}} >Instagram</p></IconContainer>
                    <IconContainer style={{color: "#1cbbdb"}} href ="https://twitter.com/wanderlustindi?t=TaN5TgYlsZIVdVTjZuZRug&amp;s=09" target="_blank"><BsTwitter/>Twitter</IconContainer>
                </FooterItem>
                <a href="https://instagram.com/wanderlusttourism.in?igshid=YmMyMTA2M2Y=" target="_blank">
                <FooterItemRow>
                        <FooterItem>
                <Address style={{width: "20rem", textAlign: "center"}}>
                    Our Instagram
                </Address>
                </FooterItem>
                 {instagram.nodes.map((instagrams: {
                     localImage : {childImageSharp: { gatsbyImageData: IGatsbyImageData };}}) => (
                        <Img image={instagrams.localImage.childImageSharp.gatsbyImageData} alt={""}></Img>
                    ))}                   
                </FooterItemRow>
                </a>
                    
                </FooterList>
            </footer>
        </>
    );
};
