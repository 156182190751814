import React from "react";
import styled from "styled-components";
import { graphql, PageProps } from "gatsby";
import { Navbar } from "./navbar";
import { TopNavbar } from "./topNavbar";
import { Footer } from "./footer";

const LayoutStyle = styled.div`
    height: 100%;
    width: 100%;
`;

export const query = graphql`
    fragment LayoutStringsFragment on Strings {
        contact
    }
`;


export const Layout: React.FC<PageProps> = ({ children }) => {
    return (
        <>
            {/* <TopNavbar/> */}
            <Navbar/>
            <LayoutStyle>
                {children}
            </LayoutStyle>
            <Footer />
        </>
    );
};