import React from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import SabaSVG from "~/assets/svg/saba";
import { Zoom, AttentionSeeker } from "react-awesome-reveal";
const NavList = styled.nav`
    
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 6rem;
    position: relative;
`;
const NavItem = styled.div`
    list-style: none;
    margin-right: 2rem;
    color: white;
    :first-child {
        margin-right: auto;
    }
`;
const LinkItem = styled(Link)`
    color: #b8b5b5;
    :hover {
        color: #3fd0d4;
    }
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-weight: 600;
    font-size: 1.2em;
    font-family: "Roboto";
    ::before {
        content: "";
        border-right: 1px solid black;
    }
    @media(max-width: 700px) {
        font-size: 1em;
    }
`;
const LinkItemSvg = styled(LinkItem)`
    position: absolute;
    top: -2rem;
    z-index: 20;
    @media(max-width: 900px) {
        position: static;
    }
`;

export const query = graphql`
    fragment LayoutStringsFragment on Strings {
        contact
    }
`;


export const Navbar: React.FC = () => {
    return (
        <>
            <NavList>
                <NavItem>
                    <LinkItemSvg to="/">
                        <Zoom>
                            <SabaSVG />
                        </Zoom>
                    </LinkItemSvg>
                </NavItem>
                <NavItem>
                    <LinkItem to="/">Home</LinkItem>
                </NavItem>
                <NavItem>
                    <LinkItem to="/enquire">Enquire</LinkItem>
                </NavItem>
                <NavItem>
                    <LinkItem to="/contact">Contact</LinkItem>
                </NavItem>                
            </NavList>
        </>
    );
};